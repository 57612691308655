import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  useGetAzureGroupsForAccountIdQuery,
  useProvisionAzureUserForPowerBiMutation,
  UserFragment,
} from '../../../../generated/graphql';
import Modal, { ModalProps } from '../../components/Modal';
import useEventCallback from '../../utils/useEventCallback';
import Title from '../../components/Title';
import CheckListFormField from '../../components/formFields/CheckListFormField';
import { CheckListItem } from '../../components/lib/CheckList';
import SaveCancelActionButtons from '../../components/ActionButtons/SaveCancelActionButtons';
import Spinner from '../../components/Spinner';
import Notification from '../../components/Notification';
import { NotificationType } from '../../components/lib/notifications/lib';

type FormValues = { groupIds: string[] };

type RetailAnalyticsAccessModalProps = Pick<ModalProps, 'onCloseClick'> & {
  accountId: string;
  user: UserFragment;
};

const RetailAnalyticsAccessModal: React.FC<RetailAnalyticsAccessModalProps> = ({
  onCloseClick,
  accountId,
  user,
}) => {
  const { loading, data } = useGetAzureGroupsForAccountIdQuery({ variables: { accountId } });
  const useFormMethods = useForm<FormValues>({
    defaultValues: {
      // Will be populated with user groups when API query for AzureUser is available
      groupIds: [],
    },
  });

  const groupItems: CheckListItem[] = useMemo(
    () =>
      data?.RetailAnalytics.retailAnalyticsUserProvisioningConfiguration.groups.map(
        ({ displayName, id }) => ({
          name: displayName,
          value: id,
        }),
      ) || [],
    [data],
  );

  const [provisionUser, { loading: isSubmitting }] = useProvisionAzureUserForPowerBiMutation();

  const submit = useEventCallback((data: FormValues) => {
    provisionUser({
      variables: {
        platformUserId: user.id,
        securityGroupIds: data.groupIds,
      },
    })
      .then(console.log)
      .finally(onCloseClick);
  });

  return (
    <Modal
      title="Retail Analytics access"
      subtitle="Specify which groups the user should be a member of."
      onCloseClick={onCloseClick}
      footer={
        <SaveCancelActionButtons
          onSave={useFormMethods.handleSubmit(submit)}
          onCancel={onCloseClick}
          isSaveDisabled={loading}
          isSaving={isSubmitting}
        />
      }
    >
      <Notification
        notificationType={NotificationType.Warning}
        content={<div>This feature is still under construction and not yet functional.</div>}
      />
      <Title size="M">{user.email}</Title>
      <FormProvider {...useFormMethods}>
        {loading ? <Spinner /> : <CheckListFormField name="groupIds" items={groupItems} />}
      </FormProvider>
    </Modal>
  );
};

export default RetailAnalyticsAccessModal;
